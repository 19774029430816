import * as React from "react"
import SwiperCore, { Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

import Promo1 from "../images/promo_1.jpg";
import Promo2 from "../images/promo_2.jpg";
import Promo3 from "../images/promo_3.jpg";
import Promo4 from "../images/promo_4.jpg";
import Promo5 from "../images/promo_5.jpg";
import Promo6 from "../images/promo_6.jpg";
import Promo7 from "../images/promo_7.jpg";
// install Swiper modules
SwiperCore.use([Autoplay]);
const SwiperHolder = styled.div`

`;

const Homeswiper = () => {
  return (
  <SwiperHolder>
<section className="section-c">
    <Swiper
                    breakpoints={{
    // when window width is >= 640px
    640: {
      width: 640,
      spaceBetween: 0,
      slidesPerView: 1,
    },
    // when window width is >= 768px
    768: {
      width: 768,
      spaceBetween: 5,
      slidesPerView: 2,
    },
    1087: {
      width: 1087,
      spaceBetween: 5,
      slidesPerView: 4,
    },
    1440: {
      width: 1440,
      spaceBetween: 5,
      slidesPerView: 5,
    },
  }}
      autoplay={{ delay: 3000}}
      // onSlideChange={() => console.log('slide change homepage')}
      // onSwiper={(swiper) => console.log(swiper)}
    >

<SwiperSlide className="gallery" style={{  
  backgroundImage: "url(" + Promo1 + ")",
}}><img src={Promo1} alt="Reform Pilates Cork Barre Yoga" className="picalter" /></SwiperSlide>
          <SwiperSlide className="gallery" style={{  
  backgroundImage: "url(" + Promo2 + ")",
}}><img src={Promo1} alt="Reform Pilates Cork Yoga" className="picalter" /></SwiperSlide>
          <SwiperSlide className="gallery" style={{  
  backgroundImage: "url(" + Promo3 + ")",
}}><img src={Promo1} alt="Reform Pilates Cork Mat Yoga" className="picalter" /></SwiperSlide>
          <SwiperSlide className="gallery" style={{  
  backgroundImage: "url(" + Promo4 + ")",
}}><img src={Promo1} alt="Reform Pilates Cork Classes" className="picalter" /></SwiperSlide>
          <SwiperSlide className="gallery" style={{  
  backgroundImage: "url(" + Promo5 + ")",
}}><img src={Promo1} alt="Reform Pilates Cork Yoga Classes" className="picalter" /></SwiperSlide>
          <SwiperSlide className="gallery" style={{  
  backgroundImage: "url(" + Promo6 + ")",
}}><img src={Promo1} alt="Reform Pilates Cork Reformer Yoga " className="picalter" /></SwiperSlide>
          <SwiperSlide className="gallery" style={{  
  backgroundImage: "url(" + Promo7 + ")",
}}><img src={Promo1} alt="Reform Pilates Yoga Cork" className="picalter" /></SwiperSlide>
<SwiperSlide className="gallery" style={{  
  backgroundImage: "url(" + Promo1 + ")",
}}><img src={Promo1} alt="Reform Pilates Cork Pilates" className="picalter" /></SwiperSlide>
          <SwiperSlide className="gallery" style={{  
  backgroundImage: "url(" + Promo2 + ")",
}}><img src={Promo1} alt="Reform Pilates Classes Cork" className="picalter" /></SwiperSlide>
          <SwiperSlide className="gallery" style={{  
  backgroundImage: "url(" + Promo3 + ")",
}}><img src={Promo1} alt="Reform Pilates Cork Yoga Women" className="picalter" /></SwiperSlide>
          <SwiperSlide className="gallery" style={{  
  backgroundImage: "url(" + Promo4 + ")",
}}><img src={Promo1} alt="Reformer Pilates Cork" className="picalter" /></SwiperSlide>
          <SwiperSlide className="gallery" style={{  
  backgroundImage: "url(" + Promo5 + ")",
}}><img src={Promo1} alt="Reform Pilates Yoga in Cork" className="picalter" /></SwiperSlide>
          <SwiperSlide className="gallery" style={{  
  backgroundImage: "url(" + Promo6 + ")",
}}><img src={Promo1} alt="Reform Pilates Cork" className="picalter" /></SwiperSlide>
          <SwiperSlide className="gallery" style={{  
  backgroundImage: "url(" + Promo7 + ")",
}}><img src={Promo1} alt="Reform Pilates Cork Yoga for Women" className="picalter" /></SwiperSlide>

    </Swiper>
    </section>
    </SwiperHolder>
  );
}

export default Homeswiper